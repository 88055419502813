import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from '../../util/axios'
import { handleLocationPath } from '../../util/utils'
import { Toast } from 'antd-mobile'
import './index.scss'
function ScanCode() {
  const { state } = useLocation()
  const utoken = localStorage.getItem('utoken')
  let [qrcode_url, set_qrcode_url] = useState(null)
  let getInfo = () => {
    let obj = handleLocationPath()
    axios
      .post('/Api/JuTwitter/Facilitator/index', {
        ...obj,
        utoken: utoken
      })
      .then((res) => {
        if (res.code == 0) {
          set_qrcode_url(res.result.qrcode_url)
        }
      })
  }
  let getUtoken = () => {
    let path = window.location.href
    let obj = handleLocationPath()
    if (obj.code) {
      axios
        .post('/Api/WxShop/WechatUser/getUtoken', {
          code: obj.code,
          uniacid: obj.uniacid
        })
        .then((res) => {
          if (res.code == 0) {
            localStorage.setItem('utoken', res.result.utoken)
            window.location.reload()
          } else {
            Toast.show({
              content: res.msg
            })
          }
        })
    } else {
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd70ddd691eb28095&component_appid=wx00c09e61d33bf315&redirect_uri=${encodeURIComponent(
        path
      )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
      window.location.replace(url)
    }
  }
  useEffect(() => {
    document.title = '扫码关注'
    if (!state) {
      utoken ? getInfo() : getUtoken()
    }
  }, [])
  return (
    <>
      <div className="scan-code" style={{ backgroundImage: 'url(' + require('../../image/back2.png') + ')' }}>
        <div className="code">
          <div>金筐筐智能服务助手</div>
          <div>消息动态提醒·收益全掌握</div>
          <div>
            <img src={state || qrcode_url} alt="" />
          </div>
          <div>长按识别二维码图片</div>
        </div>
      </div>
    </>
  )
}

export default ScanCode
